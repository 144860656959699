



















































































































import {Component, Vue, Watch} from "vue-property-decorator";
import {
  BButton,
  BCard,
  BContainer,
  BForm,
  BFormGroup,
  BFormCheckbox,
  BFormInput,
  BNav,
  BNavItem,
  BOverlay,
  BSpinner, BFormTextarea, BFormFile, BCardBody, BCardHeader, BTable
} from "bootstrap-vue";
import $store from "@/store";

import Papa from 'papaparse';
import EmployeeSearchSelect from "@/apps/components/employee-search-select.vue";

@Component({
  components: {
    BTable,
    EmployeeSearchSelect,
    BForm,
    BCardBody,
    BCardHeader,
    BFormFile,
    BFormInput,
    BFormCheckbox,
    BFormTextarea,
    BFormGroup,
    BContainer,
    BCard,
    BOverlay,
    BNav,
    BNavItem,
    BSpinner,
    BButton
  }
})
export default class RegistrationsBulkSend extends Vue {
  loading = false;
  file: File | null = null;
  employee: EmployeeAPI.User.User | null = null;

  item: RegistrationsAPI.Registrations.BulkSend = {
    employeeId: null,
    flowId: "",
    divisionId: "",
    items: []
  };

  response: RegistrationsAPI.Registrations.BulkSendResponse[] = [];

  addInvite() {
    this.item.items.push({
      firstName: "",
      lastName: "",
      emailAddress: ""
    })
  }

  removeInvite(index: number) {
    this.item.items.splice(index, 1);
  }

  async sendInvites() {
    this.loading = true;
    const send = await this.$api.registrations.registrations.bulkSend(this.item);
    if (send) {
      this.$bvToast.toast("Registrations sent", {
        title: "Success",
        variant: "success",
        solid: true
      });
      this.response = send;
    }

    this.loading = false;

  }

  @Watch('employee')
  watchEmployee() {
    if (this.employee) {
      this.item.employeeId = this.employee.uuid;
    }
  }
  @Watch("file")
  watchFile() {
    // @ts-ignore
    Papa.parse(this.file, {
      header: true,
      transformHeader: (header) => {
        return header.toLowerCase();
      },
      complete: (results) => {
        for (const result of results.data) {
          this.item.items.push({
            firstName: result["firstname"],
            lastName: result["lastname"],
            emailAddress: result["emailaddress"]
          })
        }
      }
    });
  }
}
