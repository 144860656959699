























import {Vue, Component, Prop, Watch} from 'vue-property-decorator'
import { VueAutosuggest } from 'vue-autosuggest'
import {isEqual} from "lodash";

@Component({
  components: {VueAutosuggest}
})
export default class EmployeeSearchSelect extends Vue {
  @Prop({
    default: () => {
      return "Search for an Employee"
    }, required: false
  })
  placeholder?: string;

  inputProps = {
    id: 'autosuggest__input_ajax',
    placeholder: 'Search for an employee to attach registrations to.',
    class: 'form-control',
    name: 'ajax',
  }

  mounted() {
    this.$set(this.inputProps, "placeholder", this.placeholder)

    if(this.value != null)
    {
      this.$set(this, "suggestions", [{name: "employees", data: [this.value]}])
      this.$set(this, "employeeQuery", `${this.value?.fullName} (${this.value?.email})`)
    }
  }

  @Prop({
    default: () => {
      return null
    }, required: false
  })
  state?: boolean | null;

  @Prop({
    default: () => {
      return null
    }, required: true
  })
  value?: EmployeeAPI.User.User;

  employeeQuery = null;
  suggestions = [];
  debounceMilliseconds = 250;
  timeout = null;
  selected = null;

  @Watch("value", {deep: true, immediate: true})
  watchValue() {
    if(this.value != null)
    {
      this.$set(this, "suggestions", [{name: "employees", data: [this.value]}])
      this.$set(this, "employeeQuery", `${this.value?.fullName} (${this.value?.email})`)
    }else{
      this.$set(this, "employeeQuery", ``)
    }

    this.$set(this, "selected", this.value);
  }

  @Watch("selected", {deep: true})
  watchSelected() {
    if(!isEqual(this.value, this.selected))
      this.$emit('input', this.selected);
  }

  sectionConfigs = {
    employees: {
      limit: 6,
      label: 'Employees',
      onSelected: (item, originalInput) => {
        this.selectEmployee(item.item)
      }
    }
  }

  selectEmployee(suggestedItem) {
    this.$set(this, "selected", suggestedItem);
  }

  fetchResults() {
    const {employeeQuery} = this

    clearTimeout(this.timeout)
    this.timeout = setTimeout(() => {
      const list = this.$api.employee.user.GetList(1, 10, employeeQuery)

      Promise.all([list]).then(values => {
        this.suggestions = []

        this.suggestions.push({name: 'employees', data: values[0].items})
      })
    }, this.debounceMilliseconds)
  }
}
